import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card } from "primereact/card";
import FixedWhiteNavbar from "components/Navbars/FixedWhiteNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";

export default function Faaliyetlerimiz() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios
      .get("https://eksiogluhukukofisi.pythonanywhere.com/postApi/events/")
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("API Hatası:", error);
        setError("Etkinlikler yüklenirken bir hata oluştu.");
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>FAALİYETLERİMİZ | EKŞİOĞLU HUKUK</title>
        <meta name="description" content="Ekşioglu Hukuk Bürosu Faaliyetlerimiz" />
        <link rel="canonical" href="https://www.eksiogluhukofisi.com/faaliyetlerimiz" />
      </Helmet>

      {/* Navbar */}
      <FixedWhiteNavbar />

      {/* İçerik */}
      <div className="wrapper">
        <div className="section text-center" style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
          <h1
            style={{
              fontSize: "2.8rem",
              color: "#123274",
              fontWeight: "bold",
              fontFamily: "'Benguiat Regular', serif",
              marginBottom: "1rem",
            }}
          >
            Faaliyetlerimiz
          </h1>
          <p
            style={{
              color: "#555",
              fontSize: "1.2rem",
              maxWidth: "700px",
              margin: "auto",
              marginBottom: "2rem",
              lineHeight: "1.6",
            }}
          >
            Ekşioglu Hukuk Bürosu olarak sunduğumuz faaliyet ve hizmetlerimiz aşağıda listelenmiştir.
          </p>
        </div>

        <div className="container">
          <div className="grid">
            {events.length > 0 ? (
              events.map((event) => (
                <div key={event.id} className="col-12 md:col-4" style={{ marginBottom: "2rem" }}>
                  <Card
                    className="p-shadow-4 custom-card"
                    style={{
                      padding: "1rem",
                      borderRadius: "12px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    header={
                      <img
                        src={event.title_image}
                        alt={event.title}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                      />
                    }
                  >
                    <h4 style={{ color: "#123274", fontWeight: "bold", fontSize: "1.5rem", marginBottom: "0.5rem" }}>
                      {event.title}
                    </h4>
                    <p style={{ color: "#666", fontSize: "1rem", marginBottom: "1rem", lineHeight: "1.4" }}>
                      {event.short_description}
                    </p>
                    <Link
                      to={`/faaliyet/${event.id}`}
                      state={{ event }}
                      className="p-button p-button-info"
                      style={{
                        padding: "0.8rem 1.2rem",
                        backgroundColor: "#123274",
                        color: "#fff",
                        textDecoration: "none",
                        borderRadius: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Detay
                    </Link>
                  </Card>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#888" }}>
                Yükleniyor veya etkinlik bulunamadı.
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
