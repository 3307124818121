import React from "react";
import { useParams, useLocation } from "react-router-dom";
import FixedWhiteNavbar from "components/Navbars/FixedWhiteNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";
import ImageGallery from "react-image-gallery";

export default function FaaliyetDetail() {
  const { id } = useParams();
  const location = useLocation();

  // Faaliyet bilgilerini location.state üzerinden alıyoruz
  const faaliyet = location.state?.event;

  if (!faaliyet) {
    return (
      <>
        <FixedWhiteNavbar />
        <div style={{ height: "7em" }}></div>
        <div className="container text-center" style={{ padding: "5rem 0" }}>
          <h2 style={{ fontSize: "2rem", color: "#123274", fontWeight: "bold" }}>Faaliyet bulunamadı</h2>
        </div>
        <Footer />
      </>
    );
  }

  // Görsel galerisi için resimleri hazırlıyoruz
  const galleryImages = faaliyet.images.map((img) => ({
    original: img.image,
    thumbnail: img.image,
  }));

  return (
    <>
      {/* Dinamik başlık ve meta bilgileri */}
      <Helmet>
        <title>{faaliyet.title} | EKŞİOĞLU HUKUK</title>
        <meta name="description" content={faaliyet.short_description} />
        <link rel="canonical" href={`https://www.eksiogluhukofisi.com/faaliyet/${id}`} />
      </Helmet>

      {/* Navbar */}
      <FixedWhiteNavbar />

      {/* İçerik */}
      <div className="wrapper">
        <div className="section text-center" style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
          <h1
            style={{
              fontSize: "2.8rem",
              color: "#123274",
              fontWeight: "bold",
              fontFamily: "'Benguiat Regular', serif",
              marginBottom: "1rem",
            }}
          >
            {faaliyet.title}
          </h1>
          <p
            style={{
              color: "#555",
              fontSize: "1.2rem",
              maxWidth: "700px",
              margin: "auto",
              marginBottom: "2rem",
              lineHeight: "1.6",
            }}
          >
            {faaliyet.short_description}
          </p>
        </div>

        {/* Görsel galerisi */}
        {galleryImages.length > 0 && (
          <div className="gallery-section container" style={{ marginBottom: "3rem" }}>
            <ImageGallery items={galleryImages} />
          </div>
        )}

        {/* Zengin içerik */}
        <div
          className="container content-section"
          style={{
            padding: "20px",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: "3rem",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: faaliyet.content }} />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
